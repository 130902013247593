import { Location } from "@angular/common";
import { ElementRef, Injectable, ViewChild } from "@angular/core";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import * as moment from "moment";
import { NgxPermissionsObject, NgxPermissionsService } from "ngx-permissions";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Subscriptions } from "src/app/utility/common.model";
import { IDGenerator } from "src/app/utility/id-generator.util";
import { AppLocalStorage } from "src/app/utility/local-storage.util";
import { environment } from "src/environments/environment";
import { AppError } from "../error/error.model";
import { Store } from "../store/store";
import {
  AuthTokenResponse,
  LoginCredentials,
  LogoutResponse,
  Session,
  SessionAccount,
  SessionAccountResponse,
} from "./session.model";
import {
  SessionCreatingErrorState,
  SessionCreatingState,
  LogoutLoadingState,
  LogoutLoadedState,
  LogoutErrorState,
  verifyUserLoadingState,
  verifyUserLoadedState,
  verifyUserLoadingErrorState,
  GetVersionLoadingState,
  GetVersionLoadedState,
  GetVersionLoadingErrorState,
  oktaLogoutLoadedState,
  oktaLogoutLoadingState,
  oktaLogoutLoadingErrorState,

} from "./session.state";

import { SnakbarService } from "src/app/shared/common/snakbar/snakbar.service";
//import { MyNotificationPayload } from 'src/app/pages/notifications/service/my-notification.model';

@Injectable({
  providedIn: "root",
})
export class SessionService extends Store.AbstractService {
  private static RESTRICTED_REDIRECT_URLS: string[] = [
    "/loading",
    "/login",
    "/logout",
    "/activate",
    "/callback"
  ];

  // sessionValidityTimer: NodeJS.Timer;
  sessionValidityTimer: any;

  private redirectURL: string;
  private redirectToken: string;
  session: Session;
  account: SessionAccount;
  tabSessionId: string;
  auth: any;
  private logoPath = new BehaviorSubject<string>("");
  readonly logoPath$ = this.logoPath.asObservable();

  private bannerPath = new BehaviorSubject<string>("");
  readonly bannerPath$ = this.bannerPath.asObservable();

  private fullName = new BehaviorSubject<string>("");
  readonly fullName$ = this.fullName.asObservable();

  private notificationEnable = new BehaviorSubject<boolean>(false);
  readonly notificationEnable$ = this.notificationEnable.asObservable();

  private subscriptions: Subscriptions = {
    sessionValidate: null,
  };
  public temp: boolean = false;
  @ViewChild("toast") toast: ElementRef;

  constructor(
    private router: Router,
    private permissionsService: NgxPermissionsService,
    private location: Location,
    private snakbarService: SnakbarService,
  ) {
    super();
    const me = this;

    me.tabSessionId = IDGenerator.newId();

    me.router.events.subscribe((re: RouterEvent) => {
      me.onRouterEvent(re);
    });

    window["getAccessToken"] = () => {
      return me.session.token;
    };
  }

  init() {
    const me = this;
    me.setRedirectURL();
    return new Promise<void>((resolve, reject) => {
      me.initSession(resolve, reject);
    });
  }

  redirectToLoginPage() {
    const me = this;

    me.setRedirectURL();
    me.router.navigate(["/login"]);
  }

  redirectToHomePage() {
    const me = this;

    let redirectTo = "/main-areas";
    // if (me.account.roles[0].code == 'PRO_USER') {
    redirectTo = "/main-areas";
    // }

    if (me.redirectToken && me.redirectURL) {
      redirectTo = me.redirectURL;
      me.redirectToken = null;
    }

    me.router.navigate([redirectTo]);
  }

  private setRedirectURL(url?: string) {
    const me = this;
    const sessionInfo = AppLocalStorage.get("SESSION", "SESSION");
    let redirectURL: string = url;
    if (sessionInfo?.token) {
      redirectURL = url || me.location.path();
    }

    me.redirectToken = IDGenerator.newId();

    if (SessionService.RESTRICTED_REDIRECT_URLS.indexOf(redirectURL) !== -1) {
      redirectURL = null;
    }

    me.redirectURL = redirectURL;
  }

  private isSSORequest(): boolean {
    return window.location.hash.startsWith("#id_token");
  }

  public login(credentials: LoginCredentials): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new SessionCreatingState());
    }, 0);

    me.controller
      .post<AuthTokenResponse>(
        environment.api.session.login.endpoint,
        credentials
      )
      .subscribe(
        (data: AuthTokenResponse) => {
          if (data) {
            me.session = {
              token: data.data.jwt,
              id: IDGenerator.newId(),
              expiry: moment().add(1, "day").toDate(),
            };

            AppLocalStorage.set("SESSION", "SESSION", me.session);

            me.loadAccount().subscribe(() => {
              me.redirectToHomePage();

              if (data.status == "LOGIN_SUCCESSFUL") {
                me.snakbarService.showToast(document.getElementById("toast"), {
                  title: "Logged In Successfully",
                  position: {
                    X: "Right",
                  },
                  type: "SUCCESS",
                  showCloseButton: true,
                });
              }
              me.temp = true;
              me.startSessionValidation();
              output.complete();
            });
          } else {
            setTimeout(() => {
              output.error(
                new SessionCreatingErrorState(new AppError("No Token Received"))
              );
              output.complete();
            }, 0);
          }
        },
        (e: Error) => {
          setTimeout(() => {
            output.error(new SessionCreatingErrorState(AppError.fromError(e)));
            output.complete();
          }, 0);
        }
      );

    return output;
  }

  public logout(): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new LogoutLoadingState());
    }, 0);

    me.controller
      .get(environment.api.session.logout.endpoint, null, undefined, {
        Authorization: true,
      })
      .subscribe(
        (data: LogoutResponse) => {
          if (data) {
            output.next(new LogoutLoadedState(data));
            output.complete();
          }
        },
        (e: Error) => {
          setTimeout(() => {
            output.error(new LogoutErrorState(AppError.fromError(e)));
            output.complete();
          }, 0);
        }
      );
    return output;
  }

  loadAccount(): Observable<void> {
    const me = this;
    const output = new Subject<void>();

    const path = environment.api.auth.me.endpoint;

    me.controller.get(path, null, environment.api.core.base, { Authorization: true }).subscribe(
      (data: SessionAccountResponse) => {
        this.loadCTAConfiguration();
        me.account = data?.data;
        const storage = localStorage.setItem(
          "userID", me.account.id.toString()
        );
        const userRole = localStorage.setItem("userRole", me.account?.userType?.code)
        const notificationCount = localStorage.setItem(
          "notificationCount",
          me.account.notificationCount
        );
        const powerBIToken = localStorage.setItem("powerBiAccessToken", me.account.powerBiAccessToken)

        const broadcastData = localStorage.setItem("broadcastData", me.account.broadcastData);


        let userDetails = {
          firstname: me.account?.firstName,
          lastname: me.account?.lastName,
          fullname: me.account?.fullName,
          email: me.account?.email,
          role: me.account?.userType?.label,
          role_code: me.account?.userType?.code
        };
        AppLocalStorage.set("USER_DETAILS", "USER_DETAILS", userDetails);
        const datas = localStorage.getItem("userID");

        if (me.account?.firms) {
          me.logoPath.next(me.account?.firms[0]?.logoPath);
          me.fullName.next(me.account?.fullName);
          const bannerURL =
            me.account?.firms[0]?.bannerPath &&
              me.account?.firms[0]?.bannerPath != ""
              ? JSON.parse(me.account?.firms[0]?.bannerPath)?.big
              : null;
          me.bannerPath.next(bannerURL);
          //Notification call
          me.myNotification();
        }
        me.refreshPermissions(output);
      },
      (e: any) => {
        console.log("----", e)
        output.error(new Error("Failed to Load Account",));
        output.complete();
      }
    );

    return output;
  }

  private loadCTAConfiguration(): Observable<void> {
    const me = this;
    const output = new Subject<void>();

    const path = environment.api.auth.CTAConfiguration.endpoint;

    const body = {
      query: null,
      sort: [
        {
          field: 'id',
          order: 'asc'
        }
      ],
      p: 0,
      pp: 1000
    };

    me.controller.post(path, body, environment.api.core.base, { Authorization: true }).subscribe(
      (data: any) => {
        AppLocalStorage.set("USER_DETAILS", "CTA_CONFIGURATION", this.formatCTCConfiguration(data.data.content));
      },
      (e: any) => {
        output.error(new Error("Failed to Load CTA Configuration"));
        output.complete();
      }
    );

    return output;
  }



  public refreshAccountInfo() {
    const me = this;
    me.loadAccount();
  }

  private refreshPermissions(subject: Subject<void>) {
    const me = this;

    if (me.account && me.account.permissions) {
      const permissions = Object.keys(me.account.permissions);
      me.permissionsService.loadPermissions(permissions);
      subject.next();
      subject.complete();
    } else {
      subject.error("INSUFFICIENT_PERMISSIONS");
      subject.complete();
    }
  }

  // RolePermission[]
  public getRolePermissionsFor(code: string): any[] {
    const me = this;
    if (me.account && me.account.permissions && me.account.permissions[code]) {
      return Object.values(me.account.permissions[code]);
    }
    return null;
  }

  private startSessionValidation() {
    const me = this;
    me.stopSessionValidation();
    me.sessionValidityTimer = setTimeout(() => {
      me.validateSession();
    }, 600000);
  }

  private stopSessionValidation() {
    const me = this;
    if (me.sessionValidityTimer) {
      clearTimeout(me.sessionValidityTimer);
    }
  }

  private validateSession() {
    const me = this;



    //TODO: Uncomment this whenever required

    // const path = environment.api.auth.validate.endpoint;
    // me.controller.get(path, null, undefined, { Authorization: true }).subscribe(
    //   (data: any) => {
    //     me.startSessionValidation();
    //   },
    //   (e: any) => {
    //     me.router.navigate(['logout']);
    //   }
    // );
  }

  private myNotification() {
    const me = this;
    const accountId: string[] = [];
    accountId.push(me.account?.id.toString());
  }

  private initSession(resolve: () => void, reject: (reason: AppError) => void) {
    const me = this;

    if (environment.protocol.key == "defaultJWT") {
      console.log("In Default flow")
      const session: Session = AppLocalStorage.get(
        "SESSION",
        "SESSION"
      ) as Session;
      if (me.isSessionActive(session)) {
        me.session = session;
        me.loadAccount().subscribe(
          () => {
            me.validateSession();
            resolve();
            //  me.redirectToHomePage();
            me.temp = true;
          },
          (error) => {
            reject(new AppError(error));
          }
        );
        return;
      }

      // Redirect to Login
      me.router.navigate(["login"]);
      resolve();
    } else {
      const basePath = environment.api.core.base
      const queryParams = new URLSearchParams(window.location.search);

      if (me.isCallbackPage() && queryParams.get('isAuthenticated')) {
        resolve();
        return;
      }
      // this.auth = localStorage.getItem("auth")
      if (!queryParams.get('isAuthenticated')) {

        if (environment.protocol.key == "samlKeyCloak") {
          window.open(`${basePath}` + "/auth/saml/keycloak/login", "_self")

          resolve();
        }
        else if (environment.protocol.key == "samlOkta") {
          window.open(`${basePath}` + "/auth/saml/okta/login", "_self")
          resolve();
        }
        else if (environment.protocol.key == "openIdKeyCloak") {
          window.open(`${basePath}` + "/auth/oauth2/keycloak/login", "_self")
          resolve();
        } else if (environment.protocol.key == "openIdOkta") {

          window.open(`${basePath}` + "/auth/oauth2/okta/login", "_self")
          resolve();
        }
        else if (environment.protocol.key == "openIdAzure") {
          console.log("In openId Azure")
          window.open(`${basePath}` + "/auth/oauth2/azure/login", "_self")
          resolve();
        }
      }



    }

  }




  // private isActivatePage(): boolean {
  //       return (
  //         SessionService.RESTRICTED_REDIRECT_URLS.indexOf("/activate") !== -1
  //       );
  //     }


  private isCallbackPage(): boolean {
    return (
      SessionService.RESTRICTED_REDIRECT_URLS.indexOf("/callback") !== -1
    );
  }



  public isActive(): boolean {
    return this.isSessionActive(this.session);
  }

  public clear(): Observable<any> {
    const output = new Subject<any>();
    const me = this;

    // Clear session local storage
    AppLocalStorage.clear("SESSION", "SESSION");
    AppLocalStorage.clear("USER_DETAILS", "USER_DETAILS");
    AppLocalStorage.clear("USER_DETAILS", "CTA_CONFIGURATION");
    localStorage.clear();
    // Stop session validation check
    me.stopSessionValidation();

    //stop notifications.
    // me.notificationService.stopTimer()

    // Reset Session
    me.session = null;

    setTimeout(() => {
      output.next();
    });

    return output;
  }

  public hasPermissionsAll(permissions: string[]): boolean {
    const me = this;
    const sessionPermissions: NgxPermissionsObject =
      me.permissionsService.getPermissions();
    let flag = true;

    for (const permission of permissions) {
      if (!sessionPermissions[permission]) {
        flag = false;
        break;
      }
    }

    return flag;
  }

  private isSessionActive(session: Session): boolean {
    return !!session;
  }

  private onRouterEvent(event: RouterEvent) {
    const me = this;

    if (event instanceof NavigationEnd) {
      switch (event.url) {
        case "/loading":
          me.redirectToken = null;
          break;
      }
    }
  }

  private formatCTCConfiguration(content: any[]) {
    return content.map((status: any) => {
      return {
        status: status.status,
        userType: status.userType.code,
        CTA: status.actions.map((action) => action.code)
      }
    })
  }



  verifyUser(payload): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    // const userID = localStorage.getItem("userID");

    setTimeout(() => {
      output.next(new verifyUserLoadingState());
    }, 0);

    // const path = this.controller.replaceVariables(
    //   environment.api.notifications.markAllRead.endpoint,
    //   { userId: userID }
    // );

    const path = environment.api.auth.verifyUsername.endpoint;

    me.controller
      .post(path, payload, environment.api.core.defaultBase, {
        Authorization: true,
      })
      .subscribe(
        (data: any) => {
          output.next(new verifyUserLoadedState(data));
          output.complete();
        },
        (e: any) => {
          output.error(new verifyUserLoadingErrorState(e));
          output.complete();
        }
      );

    return output;
  }

  getProductVersion() {

    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new GetVersionLoadingState());
    }, 0);
    me.controller.get(
      environment.api.auth.getVersion.endpoint,
      null,
      environment.api.core.base, {
      Authorization: null,
    }
    ).subscribe(
      (data: any) => {
        output.next(new GetVersionLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new GetVersionLoadingErrorState(e));
        output.complete();
      }
    );
    return output;

  }


  samlOktaLogout() {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new oktaLogoutLoadingState());
    }, 0);
    me.controller.delete(
      "/api/v1/sessions/me",
      "https://dev-47440351.okta.com",
    ).subscribe(
      (data: any) => {
        output.next(new oktaLogoutLoadedState(data));
        output.complete();
      },
      (e: any) => {
        output.error(new oktaLogoutLoadingErrorState(e));
        output.complete();
      }
    );
    return output;
  }

}
