import { Store } from 'src/app/core/store/store';
import { apis } from './apis';


export const environment = {
  production: false,
  environment: 'QA',
  product_version: '1.0.9.19',
  referrer: window.frames.top.document.referrer,
  localstorage: {
    key: 'traq_workflow',
  },
  // Is this the right way to do this?
  // samlKeyCloak , samlOkta ,openIdKeyCloak ,openIdOkta , defaultJWT, openIdAzure
  protocol: {
    key: "openIdAzure"
  },
  encryptionDecryption: {
    
    key: 'traqworkflow'
  },
  api: {
    core: {
      // base: `http://${hostname}:${port}/traq_workflow_qa`
      //  base: 'http://65.0.160.140:8080/traq_workflow_dev'
      //   base: 'http://13.234.1.65:8080/traq_workflow_qa'
      //  base: 'https://traq-qa.leftrightmind.com:8441/traq_workflow_qa',
      //  base: "https://traq-uat.leftrightmind.com:8441/traq_workflow_demo"
      base: "https://traq-az-dev.leftrightmind.com:8443/traq_dev_azure",
    } as Store.APIConfig,

    ...apis
  },
  formats: {
    date: {
      // Moment JS date formats
      default: 'MM-DD-YYYY',
      long: 'D-MMM-YYYY',
      short: 'D-MMM-YYYY',
    },
    dateTime: {
      // Moment JS date formats
      default: 'MM-DD-YYYY HH:mm:ss',
    },
  },
};

